import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, DataRow, Modal, useStyles} from '@ace-de/ui-components';
import {calendarIcon, closeIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {Form, ToggleSwitch, Select, Option, InputCurrency, Input, DateField, NumberInput} from '@ace-de/ui-components/form';
import {alfINARuleNameTypes, alfInvoiceChannelTypes} from '@ace-de/eua-entity-types';
import * as inaRulesActionTypes from '../inaRulesActionTypes';
import config from '../../config';

const regex = /^\d+(,\s*\d+)*$/;

const INARulesEditModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('ina_rules_edit_modal');
    const {inaRules, declineEditINARule, location, hasBackdrop, confirmEditINARule} = props;
    const inaRuleId = location?.query?.inaRuleId;
    const inaRule = inaRules.find(inaRule => inaRule.id === Number(inaRuleId));
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isVariableInvalid, setIsVariableInvalid] = useState({
        varX: false,
        varY: false,
    });
    const [isRuleActive, setIsRuleActive] = useState(inaRule.isActive);

    if (!inaRule) return null;

    const renderInputField = key => {
        let component = (
            <NumberInput
                name={key}
                value={inaRule.variables[key]}
                className={cx([
                    'global!ace-u-width--full',
                    'ace-c-number-input--small',
                ], {
                    'ace-c-number-input--has-error': isVariableInvalid[key],
                })}
            />
        );
        switch (inaRule.name) {
            case alfINARuleNameTypes.TOTAL_APPROVED_AMOUNT:
            case alfINARuleNameTypes.OPEN_ACCOUNTS_RECEIVABLE_FOR_MEMBER:
            case alfINARuleNameTypes.REQUESTED_AMOUNT_FOR_INVOICE: {
                if (key === 'varX') {
                    component = (
                        <InputCurrency
                            name={key}
                            value={Number(inaRule.variables[key].replace('€', ''))}
                            className={cx([
                                'global!ace-u-width--full',
                                'ace-c-input-currency--small',
                            ], {
                                'ace-c-input-currency--has-error': isVariableInvalid[key],
                            })}
                        />
                    );
                }
                break;
            }
            case alfINARuleNameTypes.DUNNING_LEVEL: {
                component = (
                    <Select
                        name={key}
                        value={inaRule.variables[key]}
                        className={cx([
                            'ace-c-select--small',
                            'global!ace-u-width--full',
                        ])}
                    >
                        {[...Array(config.MAXIMUM_INA_RULE_DUNNING_LEVEL).keys()].map(dunningLevel => (
                            <Option
                                key={dunningLevel}
                                name={`dunningLevel-${dunningLevel}`}
                                value={dunningLevel}
                            >
                                {dunningLevel.toString()}
                            </Option>
                        ))}
                    </Select>
                );
                break;
            }
            case alfINARuleNameTypes.CREDITOR_AUTHORIZATION: {
                component = (
                    <Input
                        name={key}
                        value={inaRule.variables[key]}
                        className={cx([
                            'global!ace-u-width--full',
                            'ace-c-input--small',
                        ], {
                            'ace-c-input--has-error': isVariableInvalid[key],
                        })}
                    />
                );
                break;
            }
            case alfINARuleNameTypes.BOOKING_DATE_NOT_ALLOWED_FROM_DATE: {
                component = (
                    <DateField
                        className={cx([
                            'global!ace-u-width--full', {
                                'ace-c-date-field__input--has-error': isVariableInvalid[key],
                            },
                        ])}
                        name={key}
                        value={inaRule.bookingDateFrom[0] === '+'
                            ? inaRule.bookingDateFrom.substring(1)
                            : inaRule.bookingDateFrom}
                        icon={calendarIcon}
                        format="DD.MM."
                        onInvalidDate={value => {
                            setIsVariableInvalid(prevState => ({
                                ...prevState,
                                varX: value,
                            }));
                        }}
                    />
                );
                break;
            }
            default: {
                component = (
                    <NumberInput
                        name={key}
                        value={inaRule.variables[key]}
                        className={cx([
                            'global!ace-u-width--full',
                            'ace-c-number-input--small',
                        ], {
                            'ace-c-number-input--has-error': isVariableInvalid[key],
                        })}
                    />
                );
            }
        }
        return component;
    };

    const validateFormValues = formValues => {
        let areFormValuesValid = true;
        const varX = 'varX';
        const varY = 'varY';

        switch (inaRule.name) {
            case alfINARuleNameTypes.NUMBER_OF_INVOICES_IN_CASE:
            case alfINARuleNameTypes.AMOUNT_OF_DAMAGE_CASES: {
                if (!Number.isInteger(formValues[varX] * 1)
                    || Number(formValues[varX]) > config.MAXIMUM_INTEGER_SIZE
                    || (formValues[varY] && (!Number.isInteger(formValues[varY] * 1)
                        || Number(formValues[varY]) > config.MAXIMUM_INTEGER_SIZE))
                ) {
                    areFormValuesValid = false;
                }
                setIsVariableInvalid(prevState => ({
                    ...prevState,
                    varX: !Number.isInteger(formValues[varX] * 1)
                        || Number(formValues[varX]) > config.MAXIMUM_INTEGER_SIZE,
                    ...(formValues[varY] && {
                        varY: !Number.isInteger(formValues[varY] * 1)
                            || Number(formValues[varY]) > config.MAXIMUM_INTEGER_SIZE,
                    }),
                }));
                break;
            }
            case alfINARuleNameTypes.TOTAL_APPROVED_AMOUNT:
            case alfINARuleNameTypes.OPEN_ACCOUNTS_RECEIVABLE_FOR_MEMBER:
            case alfINARuleNameTypes.REQUESTED_AMOUNT_FOR_INVOICE: {
                if ((Number(formValues[varX]) !== 0 && !Number(formValues[varX]))
                    || (formValues[varY] && (!Number.isInteger(formValues[varY] * 1)
                        || Number(formValues[varY]) > config.MAXIMUM_INTEGER_SIZE
                    ))) {
                    areFormValuesValid = false;
                }
                setIsVariableInvalid(prevState => ({
                    ...prevState,
                    varX: Number(formValues[varX]) !== 0 && !Number(formValues[varX]),
                    ...(formValues[varY] && {varY: !Number.isInteger(formValues[varY] * 1)
                            || Number(formValues[varY]) > config.MAXIMUM_INTEGER_SIZE}),
                }));
                break;
            }
            case alfINARuleNameTypes.BOOKING_DATE_NOT_ALLOWED_FROM_DATE: {
                const date = moment(formValues[varX]);
                if (!date.isValid()) areFormValuesValid = false;
                setIsVariableInvalid(prevState => ({
                    ...prevState,
                    varX: !date.isValid(),
                }));
                break;
            }
            case alfINARuleNameTypes.CREDITOR_AUTHORIZATION: {
                if (!regex.test(formValues[varX])) {
                    areFormValuesValid = false;
                }
                setIsVariableInvalid(prevState => ({
                    ...prevState,
                    varX: !regex.test(formValues[varX]),
                }));
                break;
            }
            default:
        }
        return areFormValuesValid;
    };

    const handleOnChange = formValues => {
        let shouldButtonBeDisabled = false;
        Object.keys(formValues).forEach(key => {
            if (key && key !== 'isActive'
                && ((formValues[key] !== 0 && !formValues[key])
                    || (typeof formValues[key] === 'object' && formValues[key].length === 0))) {
                shouldButtonBeDisabled = true;
            }
        });
        setIsButtonDisabled(shouldButtonBeDisabled);
    };

    const handleOnSubmit = formValues => {
        const isFormValid = validateFormValues(formValues);
        if (!isFormValid) return;

        const varX = 'varX';
        const varY = 'varY';
        const editedINARule = {
            channels: formValues['channels'],
            isActive: isRuleActive,
            name: inaRule.name,
        };
        switch (editedINARule.name) {
            case alfINARuleNameTypes.AMOUNT_OF_DAMAGE_CASES: {
                editedINARule.casesAmountLimit = Number(formValues[varX]);
                editedINARule.monthsCountLimit = Number(formValues[varY]);
                break;
            }
            case alfINARuleNameTypes.TOTAL_APPROVED_AMOUNT: {
                editedINARule.totalAmountLimit = formValues[varX];
                editedINARule.totalAmountMonthLimit = Number(formValues[varY]);
                break;
            }
            case alfINARuleNameTypes.BOOKING_DATE_NOT_ALLOWED_FROM_DATE: {
                editedINARule.bookingDateFrom = formValues[varX];
                break;
            }
            case alfINARuleNameTypes.CREDITOR_AUTHORIZATION: {
                editedINARule.creditorIds = formValues[varX].split(',').map(creditorId => {
                    return creditorId.trim();
                });
                break;
            }
            case alfINARuleNameTypes.DUNNING_LEVEL: {
                editedINARule.dunningLevel = formValues[varX];
                break;
            }
            case alfINARuleNameTypes.NUMBER_OF_INVOICES_IN_CASE: {
                editedINARule.numberOfInvoices = Number(formValues[varX]);
                break;
            }
            case alfINARuleNameTypes.OPEN_ACCOUNTS_RECEIVABLE_FOR_MEMBER: {
                editedINARule.openAccountReceivableMemberLimit = formValues[varX];
                break;
            }
            case alfINARuleNameTypes.REQUESTED_AMOUNT_FOR_INVOICE: {
                editedINARule.requestedAmountForInvoiceLimit = formValues[varX];
                break;
            }
            default:
        }
        confirmEditINARule({inaRuleId: inaRule.id, inaRuleData: editedINARule});
    };

    return (
        <Modal
            title={translateModal(`modal_title.${inaRule.name.toLowerCase()}`)}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineEditINARule}
                />
        )}
            contentClassName={cx(['global!ace-u-margin--top-32', 'global!ace-u-modal-content-size--m'])}
            hasBackdrop={hasBackdrop}
        >
            <div className={cx('global!ace-u-width--full')}>
                <Form name="editINARuleForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                    <div
                        className={cx([
                            'global!ace-u-padding--16',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-margin--right-64',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateModal('radio_button_label.active_rule')}
                        </p>
                        <ToggleSwitch
                            name="isActive"
                            value={true}
                            isSelected={isRuleActive}
                            onChange={value => setIsRuleActive(value)}
                        />
                    </div>
                    <DataRow
                        label={translateModal('data_row_label.channels')}
                        qaIdent="ina-rule-channels"
                        className={cx('ace-c-data-row--condensed')}
                        isFieldRequired={true}
                    >
                        <Select
                            name="channels"
                            value={inaRule.channels}
                            isMultipleChoice={true}
                            className={cx([
                                'ace-c-select--small',
                                'global!ace-u-width--full',
                            ])}
                        >
                            {Object.values(alfInvoiceChannelTypes).map(channel => (
                                <Option
                                    key={channel}
                                    name={`channel${channel}`}
                                    value={channel}
                                >
                                    {channel}
                                </Option>
                            ))}
                        </Select>
                    </DataRow>
                    {inaRule.variables && Object.keys(inaRule.variables).map(key => (
                        <DataRow
                            key={key}
                            label={translateModal(`${key === 'varX'
                                ? 'data_row_label_variable_x'
                                : 'data_row_label_variable_y'
                            }.${inaRule.name.toLowerCase()}`)}
                            qaIdent={`${key === 'varX'
                                ? 'ina-rule-variable-x'
                                : 'ina-rule-variable-y'
                            }-${inaRule.name.toLowerCase()}`}
                            className={cx('ace-c-data-row--condensed')}
                            isFieldRequired={true}
                        >
                            <div className={cx('global!ace-u-width--full')}>
                                {renderInputField(key)}
                                {isVariableInvalid[key] && (
                                    <p
                                        className={cx([
                                            'global!ace-u-typography--variant-caption',
                                            'global!ace-u-typography--color-warning',
                                        ])}
                                    >
                                        {translateModal('error_message.invalid_format')}
                                    </p>
                                )}
                            </div>
                        </DataRow>
                    ))}
                    <div
                        className={cx([
                            'global!ace-u-margin--top-24',
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                        ])}
                    >
                        <ButtonPrimary type="submit" isDisabled={isButtonDisabled}>
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

INARulesEditModal.propTypes = {
    inaRules: PropTypes.array,
    declineEditINARule: PropTypes.func.isRequired,
    confirmEditINARule: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    hasBackdrop: PropTypes.bool,
};

INARulesEditModal.defaultProps = {
    inaRules: [],
    hasBackdrop: false,
};

const mapStateToProps = state => {
    return {
        inaRules: state.inaRules.inaRules,
    };
};

const mapDispatchToProps = dispatch => ({
    declineEditINARule: () => dispatch({
        type: inaRulesActionTypes.DECLINE_INA_RULE_EDIT,
    }),
    confirmEditINARule: payload => dispatch({
        type: inaRulesActionTypes.CONFIRM_INA_RULE_EDIT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(INARulesEditModal);
